<template>
  <div class="category">
    <el-card>
      <el-button  type="primary"  size="mini" @click="append({catId:0,catLevel:0})" icon="el-icon-circle-plus" > 添加一级分类</el-button>
      <el-tree :data="menus"
               :props="defaultProps"
               :expand-on-click-node="false"
               node-key="catId"
               :default-expanded-keys="expandedKey"
      >
        <span  slot-scope="{ node, data }">
        <div class="custom-tree-node">
          <span >{{ node.label }}</span>
          <span class="span-button" >
            <el-button v-if="node.level<=2" type="text"  size="mini" @click="() => append(data)" icon="el-icon-circle-plus" > 添加</el-button>
            <el-button type="text"  size="mini" @click="edit(data)" icon="el-icon-edit-outline" > 编辑 </el-button>
            <el-button v-if="node.childNodes==0" type="text" size="mini" @click="() => showDeleteDialog(node, data)" icon="el-icon-delete" >删除 </el-button>
          </span>
        </div>
      </span>
      </el-tree>
    </el-card>


    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :beforeClose="cancel">
      <el-form :model="form" label-position="left" label-width="120px" size="small">
        <el-form-item label="分类名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type = "primary" @click="saveCategory()">确定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import {getCategoryListWithTree,deleteCategoryByCatIds,saveCategory,getCategoryByCatId,editCategory} from "@/services/product";

export default {
  name: 'category',
  data() {
    return {
      title:'',
      dialogType:'',
      dialogVisible:false,
      form:{},
      expandedKey:[],
      menus: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    };
  },
  methods: {


     // 查询树形分类列表
    async loadCategoryListTree() {
      const {data} = await getCategoryListWithTree()
      if (200 === data.code) {
        this.menus = data.data
      } else {
        this.$message.error('查询树形分类列表失败')
      }
    },

    async edit(dat) {
      this.dialogVisible=true
      this.title='编辑分类'
      this.dialogType='edit'
      const {data} = await getCategoryByCatId(dat.catId)
      if (data.code ===200) {
        this.form = data.data
      } else {
        this.$message.error('获取分类信息失败')
      }
    },

    // 打开添加编辑框
    append(data) {
      this.dialogVisible=true
      this.title='新增分类'
      this.dialogType='add'
      this.form.parentCid=data.catId
      this.form.catLevel=data.catLevel*1 +1
    },

    cancel() {
      this.dialogVisible = false
      this.form = {}
    },
    // 添加三级分类
    async saveCategory() {
      if (this.dialogType=='add') {
        const {data} = await saveCategory(this.form);
        if (200== data.code) {
          this.$message.success('操作成功')
          await this.loadCategoryListTree()
          // 设置需要默认展开的菜单
          this.expandedKey = [this.form.parentCid]
          this.form = {}
          this.dialogVisible = false
        }else {
          this.$message.error(data.message)
        }
      }
      if (this.dialogType=='edit') {
        const {data} = await editCategory(this.form);
        if (200== data.code) {
          this.$message.success('操作成功')
          await this.loadCategoryListTree()
          // 设置需要默认展开的菜单
          this.expandedKey = [this.form.catId]
          this.form = {}
          this.dialogVisible = false
        }else {
          this.$message.error(data.message)
        }
      }
    },

    // 删除提示弹框
    async showDeleteDialog(node, dat) {
      try {
        await this.showConfirmationDialog(dat);
        // 用户点击了确定按钮的逻辑
        await this.remove(node, dat);
      } catch {
        this.$message.info('取消操作')
        // 用户点击了取消按钮的逻辑
        // 可以不做任何操作，或者执行取消操作
      }
    },
    //删除分类
    async remove(node, dat) {
      let catIds = [dat.catId]
      const {data} =await deleteCategoryByCatIds(catIds)
      if (200 === data.code) {
        this.$message.success('操作成功')
        await this.loadCategoryListTree()
        // 设置需要默认展开的菜单
        this.expandedKey = [dat.parentCid]
      } else {
        this.$message.error('操作失败')
      }
    },
    // 提示弹框
    showConfirmationDialog(data) {
      return new Promise((resolve, reject) => {
        this.$confirm(`是否删除【${data.name}】？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          resolve(); // 用户点击了确定按钮，将Promise解析为成功状态
        }).catch(() => {
          reject(); // 用户点击了取消按钮，将Promise解析为失败状态
        });
      });
    },
  },
  created() {
    this.loadCategoryListTree()
  },
  filters: {}
}
</script>

<style lang="scss" scoped>
.category .el-tree{
  padding: 36px;
}
.custom-tree-node {
  flex: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-right: 20px;
}
.custom-tree-node span{
  display: block;
  width: 200px;
}

</style>
